import {
  getCartToken,
  getIsGuestPaymentStep,
  getIsAddingNewCard,
  getOrderToken,
  getToken,
  getIsApplePayment,
} from '@/selectors';
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import qs from 'qs';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.NEXT_PUBLIC_API_URL}`,
  paramsSerializer: params => qs.stringify(params, {arrayFormat: 'brackets'}),
});

const baseQueryWithInterceptor = async (args, api, extraOptions) => {
  const version = '1.1.1'.substring(0).split(' ')[0];
  const state = api.getState();
  const token = getToken(state);
  const cartToken = getCartToken(state);
  const orderToken = getOrderToken(state);
  const isGuestPayment = getIsGuestPaymentStep(state);
  const isToAddNewCard = getIsAddingNewCard(state);
  const isApplePayment = getIsApplePayment(state);
  const userAgent = navigator.userAgent || 'Unknown';

  args.headers = {
    ...args.headers,
    'User-Agent': userAgent,
    'App-Version': version,
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
  };

  if (
    api.endpoint === 'getOrderStatus' ||
    (api.endpoint === 'completeCheckout' &&
      (isGuestPayment || isToAddNewCard || isApplePayment)) ||
    (api.endpoint === 'cancelOrder' && isGuestPayment)
  ) {
    args.headers['X-Spree-Order-Token'] = orderToken;
  } else if (
    api.endpoint !== 'getUnpaidOrder' &&
    api.endpoint !== 'createPayment' &&
    api.endpoint !== 'getAllPaymentMethods' &&
    api.endpoint !== 'getUnpaidOrderPreparationAbility'
  ) {
    args.headers['X-Spree-Order-Token'] = cartToken;
  }

  if (token) {
    args.headers = {
      ...args.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  let result = await baseQuery(args, api, extraOptions);
  console.log(api.endpoint, args, result);

  return {
    ...result,
    error: result?.error?.data || result?.error,
  };
};

export const api = createApi({
  baseQuery: baseQueryWithInterceptor,
  endpoints: () => ({}),
  tagTypes: ['Cart', 'Address', 'User', 'Card', 'Products', 'Favorites'],
});
